import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ChangeEvent, createContext, useContext, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { FiArrowUp, FiChevronDown } from "react-icons/fi";

const DashboardContext = createContext<any>(null);

export default () => {
  const [amountToSend, setAmountToSend] = useState<number | null>(null);
  const [step, setStep] = useState(1);

  const handleAmountToSend = (e: number) => setAmountToSend(e);

  return (
    <DashboardContext.Provider
      value={{ amountToSend, handleAmountToSend, step, setStep }}
    >
      <Flex mx="auto" gap={28} px={5}>
        <Box w={{ base: "100%", md: "sm" }} px={{ base: 5, md: 0 }}>
          <Main />
        </Box>
        <VStack
          w="sm"
          spacing={16}
          py={28}
          display={{ base: "none", md: "flex" }}
        >
          <Image src="/investment-live-opportunities.png" w="100%" />
          <VStack spacing={5}>
            <Heading w="100%" fontSize="1rem">
              WATCH HOW TO USE SALVAPAY
            </Heading>
            <Image src="/how-salvapay-works.png" w="100%" />
          </VStack>
          <VStack spacing={5} w="100%">
            <Heading w="100%" fontSize="1rem">
              DOWNLOAD OUR APP FROM
            </Heading>
            <HStack>
              <Button py={5} px={2} colorScheme="none" color="white" bg="black">
                <HStack>
                  <Image src="/playstore.png" />
                  <VStack spacing={0} alignItems="left" textAlign="left">
                    <Text fontSize="0.6rem">GET IT ON</Text>
                    <Heading fontSize="1rem">Google Play</Heading>
                  </VStack>
                </HStack>
              </Button>
              <Button py={5} px={2} variant="outline" borderColor="black">
                <HStack>
                  <Image src="/apple-logo.png" />
                  <VStack spacing={0} alignItems="left" textAlign="left">
                    <Text fontSize="0.6rem">Download on the</Text>
                    <Heading fontSize="1rem">App Store</Heading>
                  </VStack>
                </HStack>
              </Button>
            </HStack>
          </VStack>
        </VStack>
      </Flex>
    </DashboardContext.Provider>
  );
};

const Main = () => {
  const { step } = useContext(DashboardContext);

  switch (step) {
    case 1:
      return <FirstComponent />;

    default:
      return <SecondComponent />;
  }
};

const FirstComponent = () => {
  const { amountToSend, handleAmountToSend, setStep } =
    useContext(DashboardContext);

  return (
    <VStack py={10} spacing={10}>
      <VStack w="100%">
        <Heading
          visibility={amountToSend ? "hidden" : "visible"}
          w="100%"
          fontSize="1.2rem"
          fontWeight="600"
        >
          Welcome Jon!
        </Heading>
        {amountToSend ? (
          <>
            <HStack w="100%" spacing={3}>
              <Text color="blackAlpha.700">Home</Text>
              <Text color="blackAlpha.700">&gt;</Text>
              <Text fontWeight="600">Send money</Text>
            </HStack>
          </>
        ) : (
          <Text color="blackAlpha.700" w="100%">
            Today's date is 15/12/2022 01:00:56 PM
          </Text>
        )}
      </VStack>
      <HStack w="100%" justifyContent="space-between">
        <Button
          w="45%"
          bg="transparent"
          borderBottomWidth="2px"
          borderColor="brand.purple"
          borderRadius={0}
        >
          Send money
        </Button>
        <Button
          w="45%"
          bg="transparent"
          borderRadius={0}
          display={{ base: "block", md: "none" }}
        >
          All transactions
        </Button>
      </HStack>
      <ExhangeRate
        props={{
          action: handleAmountToSend,
          title: "You send",
        }}
      />
      <ExhangeRate
        props={{ action: () => null, title: "Recipient receives" }}
      />
      <VStack py={5} w="100%" color="blackAlpha.700" spacing={5}>
        <Flex fontWeight="600" w="100%">
          <Text>Service charge</Text>
          <Spacer />
          <Text>3.5 USD</Text>
        </Flex>
        <Flex fontWeight="600" w="100%">
          <Text>Conversion rate</Text>
        </Flex>
        <Flex fontWeight="600" w="100%">
          <Text>Total amount</Text>
          <Spacer />
          <Text color="black">1,003.5 USD</Text>
        </Flex>
      </VStack>
      <Text w="100%" color="black">
        Payment should reflect in recipient's account after 48 hours
      </Text>
      <Button
        isDisabled={amountToSend ? false : true}
        _disabled={{ bg: "brand.gray", color: "white" }}
        colorScheme="purple"
        bg="brand.purple"
        w="85%"
        borderRadius={3}
        onClick={() => setStep(2)}
      >
        Continue
      </Button>
      <VStack
        visibility={amountToSend ? "hidden" : "visible"}
        w="100%"
        spacing={8}
        display={{ base: "none", md: "flex" }}
      >
        <Heading
          borderBottomWidth="2px"
          borderColor="brand.purple"
          px={3}
          py={1}
          fontSize="1.5rem"
          fontWeight="500"
          alignSelf="flex-start"
        >
          Recent transactions
        </Heading>
        <VStack w="100%">
          <Text w="100%" color="blackAlpha.700">
            November 22, 2022
          </Text>
          <TransactionCard
            props={{
              icon: <FiArrowUp />,
              title: "Write Famous",
              description: "As discussed initially",
              amount: 1000,
              date: "Jun 10",
            }}
          />
          <TransactionCard
            props={{
              icon: <FiArrowUp />,
              title: "Write Famous",
              description: "As discussed initially",
              amount: 1000,
              date: "Jun 10",
            }}
          />
        </VStack>
        <Button
          variant="outline"
          borderColor="brand.purple"
          color="brand.purple"
          w="80%"
        >
          View all transaction
        </Button>
      </VStack>
    </VStack>
  );
};

const TransactionCard = ({ props }: TransactionCardProps) => {
  const { icon, title, description, amount, date } = props;

  return (
    <HStack w="100%">
      <Flex
        justifyContent="center"
        alignItems="center"
        h="2.5rem"
        w="2.5rem"
        bg="red.100"
        borderRadius={10}
        color="red.500"
      >
        {icon}
      </Flex>
      <VStack w="100%" spacing={1}>
        <HStack
          fontSize="1.1rem"
          fontWeight="600"
          w="100%"
          justifyContent="space-between"
        >
          <Heading fontSize="inherit">{title}</Heading>
          <Text color="red.500">${amount}</Text>
        </HStack>
        <HStack
          w="100%"
          justifyContent="space-between"
          fontSize="0.8rem"
          color="blackAlpha.700"
        >
          <Text>{description}</Text>
          <Text>{date}</Text>
        </HStack>
      </VStack>
    </HStack>
  );
};

const ExhangeRate = ({ props }: ExhangeRateProps) => {
  const { action, title } = props;

  return (
    <FormControl
      pt={3}
      pos="relative"
      w={{ base: "100%", md: "90%" }}
      alignSelf="flex-start"
    >
      <FormLabel pos="absolute" top={0} left={2} px={3} bg="white" zIndex={20}>
        {title}
      </FormLabel>
      <InputGroup>
        <Input onChange={action} />
        <InputRightElement w="fit-content">
          <Menu>
            <MenuButton
              as={Button}
              colorScheme="purple"
              bg="brand.purple"
              rightIcon={<FiChevronDown />}
              leftIcon={<ReactCountryFlag countryCode="US" svg />}
              borderRadius={5}
            >
              USD
            </MenuButton>
          </Menu>
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};

const SecondComponent = () => {
  const { setStep } = useContext(DashboardContext);

  const [modalActive, setModalActive] = useState(false);

  return (
    <VStack py={10} spacing={10}>
      {modalActive ? <FeedbackModal /> : null}
      <VStack w="100%">
        <Heading
          w="100%"
          fontSize="1.2rem"
          fontWeight="600"
          visibility="hidden"
        >
          Welcome Jon!
        </Heading>
        <>
          <HStack w="100%" spacing={3}>
            <Text color="blackAlpha.700">Home</Text>
            <Text color="blackAlpha.700">&gt;</Text>
            <Text fontWeight="600">Send money</Text>
          </HStack>
        </>
      </VStack>
      <HStack w="100%" justifyContent="space-between">
        <Button
          w="45%"
          bg="transparent"
          borderBottomWidth="2px"
          borderColor="brand.purple"
          borderRadius={0}
        >
          Send money
        </Button>
        <Button
          w="45%"
          bg="transparent"
          borderRadius={0}
          display={{ base: "block", md: "none" }}
        >
          All transactions
        </Button>
      </HStack>
      <VStack w="100%" spacing={5}>
        <Heading w="100%" fontSize=".8rem" fontWeight="500">
          Sender information
        </Heading>
        <Box pos="relative" pt={3} w="100%">
          <Menu>
            <Text pos="absolute" top={0} left={3} px={2} bg="white" zIndex={20}>
              Select card currency
            </Text>
            <MenuButton
              w="100%"
              as={Button}
              rightIcon={<FiChevronDown />}
              bg="transparent"
              borderWidth="1px"
              borderColor="gray.300"
              textAlign="left"
              h="3rem"
            >
              <HStack>
                <ReactCountryFlag countryCode="US" svg />
                <Text>United States</Text>
              </HStack>
            </MenuButton>
            <MenuList zIndex={30}>
              {Array.from("123456789").map((_, index) => {
                return (
                  <MenuItem key={index}>
                    <HStack>
                      <ReactCountryFlag countryCode="US" svg />
                      <Text>United States</Text>
                    </HStack>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        </Box>
        <FormControl pos="relative" w="100%" pt={3.5}>
          <FormLabel
            pos="absolute"
            top={0}
            left={3}
            px={2}
            bg="white"
            zIndex={20}
            h="3rem"
          >
            Card number
          </FormLabel>
          <Input w="100%" />
        </FormControl>
        <HStack>
          <FormControl pos="relative" w="100%" pt={3.5}>
            <FormLabel
              pos="absolute"
              top={0}
              left={3}
              px={2}
              bg="white"
              zIndex={20}
              h="3rem"
            >
              Expiry date
            </FormLabel>
            <Input w="100%" />
          </FormControl>
          <FormControl pos="relative" w="100%" pt={3.5}>
            <FormLabel
              pos="absolute"
              top={0}
              left={3}
              px={2}
              bg="white"
              zIndex={20}
              h="3rem"
            >
              CVV
            </FormLabel>
            <Input w="100%" />
          </FormControl>
        </HStack>
        <FormControl pos="relative" w="100%" pt={3.5}>
          <FormLabel
            pos="absolute"
            top={0}
            left={3}
            px={2}
            bg="white"
            zIndex={20}
            h="3rem"
          >
            Amount
          </FormLabel>
          <Input w="100%" />
        </FormControl>
        <Heading py={3} w="100%" fontSize=".8rem" fontWeight="500">
          Recipient information
        </Heading>
        <FormControl pos="relative" w="100%" pt={3.5}>
          <FormLabel
            pos="absolute"
            top={0}
            left={3}
            px={2}
            bg="white"
            zIndex={20}
            h="3rem"
          >
            Account number
          </FormLabel>
          <Input w="100%" />
        </FormControl>
        <FormControl pos="relative" w="100%" pt={3.5}>
          <FormLabel
            pos="absolute"
            top={0}
            left={3}
            px={2}
            bg="white"
            zIndex={20}
            h="3rem"
          >
            Account name
          </FormLabel>
          <Input w="100%" />
        </FormControl>
        <FormControl pos="relative" w="100%" pt={3.5}>
          <FormLabel
            pos="absolute"
            top={0}
            left={3}
            px={2}
            bg="white"
            zIndex={20}
            h="3rem"
          >
            Narration (Optional)
          </FormLabel>
          <Input w="100%" />
        </FormControl>
        <VStack
          w="100%"
          p={4}
          borderWidth="1px"
          borderColor="blue.500"
          borderRadius={5}
          spacing={5}
        >
          <Text>Enter your 4-digit PIN</Text>
          <HStack>
            <PinInput>
              <PinInputField borderColor="blue.500" />
              <PinInputField borderColor="blue.500" />
              <PinInputField borderColor="blue.500" />
              <PinInputField borderColor="blue.500" />
            </PinInput>
          </HStack>
        </VStack>
        <Button
          colorScheme="green"
          h="3rem"
          w="80%"
          onClick={() => setModalActive(true)}
        >
          Send
        </Button>
      </VStack>
    </VStack>
  );
};

const FeedbackModal = () => {
  const { setStep } = useContext(DashboardContext);
  const { onClose } = useDisclosure();

  return (
    <Modal
      onClose={onClose}
      isOpen={true}
      motionPreset="slideInBottom"
      isCentered
      size={{ base: "sm", md: "md" }}
    >
      <ModalOverlay
        bg="blackAlpha.100"
        backdropFilter="blur(3px) hue-rotate(0deg)"
      />
      <ModalContent>
        <ModalBody p={5}>
          <VStack spacing={6} alignItems={{ base: "center", md: "flex-start" }}>
            <Flex w="fit-content" p={1} borderRadius="100%" bg="whatsapp.50">
              <Image src="/check-circle.svg" w={6} />
            </Flex>
            <Heading fontSize="1.2rem" fontWeight="600">
              TRANSACTION SUCCESSFUL &#128077;
            </Heading>
            <Text textAlign={{ base: "center", md: "left" }}>
              Your transfer of <b>1,000CAD</b> to <b>Write Famous</b> was
              completed successfully
            </Text>
            <Flex
              flexDir="column"
              w="100%"
              flexWrap={{ base: "wrap", md: "nowrap" }}
              gap={4}
            >
              <Button w="100%" colorScheme="purple" bg="brand.purple">
                Share receipt
              </Button>
              <Button
                w="100%"
                borderColor="brand.purple"
                variant="outline"
                color="brand.purple"
                onClick={() => setStep(1)}
              >
                Go home
              </Button>
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
