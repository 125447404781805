import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    brand: {
      purple: "#020145",
      gray: "#e7e7e7",
    },
  },
  fonts: {
    heading: "Bozon",
    body: "Bozon",
  },
});
