import { ChakraProvider } from "@chakra-ui/react";
import { createContext, useState } from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import { theme } from "./chakra/";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import DashboardWrapper from "./pages/dashboard/DashboardWrapper";
import Dashboard from "./pages/dashboard/Dashboard";
import Settings from "./pages/dashboard/Settings";
import Transactions from "./pages/dashboard/Transactions";

export const GlobalContext = createContext<any>(null);

export default () => {
  const [authenticated, setAuthenticated] = useState(false);

  return (
    <GlobalContext.Provider value={{ authenticated, setAuthenticated }}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/dashboard" element={<DashboardWrapper />}>
              <Route path="" element={<Dashboard />} />
              <Route path="settings" element={<Settings />} />
              <Route path="transactions" element={<Transactions />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </GlobalContext.Provider>
  );
};
  