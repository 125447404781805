import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { createContext, useContext, useEffect, useState } from "react";
import { TbArrowsDownUp, TbHome2, TbLogout, TbSettings } from "react-icons/tb";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../../components/Header";

const DashboardWrapperContext = createContext<any>({});

export default () => {
  const [mobile] = useMediaQuery("(max-width: 480px)");
  const [currentPage, setCurrentPage] = useState("/");

  useEffect(() => {
    const getCurrentPage = () => {
      const location = window.location.pathname;

      if (location.includes("settings")) {
        setCurrentPage("/settings");
      } else if (location.includes("transactions")) {
        setCurrentPage("/transactions");
      } else {
        setCurrentPage("/");
      }
    };

    getCurrentPage();
  }, []);

  return mobile ? (
    <>
      <Header />
      <Outlet />
    </>
  ) : (
    <DashboardWrapperContext.Provider value={{ currentPage, setCurrentPage }}>
      <Flex>
        <VStack minH="100vh" bg="brand.purple" w="16rem" py={16} spacing={8}>
          <Image src="/salvapay-icon.svg" />
          <NavigationButton
            props={{
              icon: <TbHome2 />,
              link: "/",
              title: "Home",
            }}
          />
          <NavigationButton
            props={{
              icon: <TbArrowsDownUp />,
              link: "/transactions",
              title: "All transactions",
            }}
          />
          <NavigationButton
            props={{
              icon: <TbSettings />,
              link: "/settings",
              title: "Settings",
            }}
          />
          <Button
            leftIcon={<TbLogout size={20} />}
            w="100%"
            borderRadius={0}
            h="3rem"
            bg="transparent"
            color="red"
            fontWeight="500"
          >
            <Text w="100%" textAlign="left">
              Logout
            </Text>
          </Button>
        </VStack>
        <Outlet />
      </Flex>
    </DashboardWrapperContext.Provider>
  );
};

const NavigationButton = ({ props }: NavigationButtonProps) => {
  const navigate = useNavigate();
  const { currentPage, setCurrentPage } = useContext(DashboardWrapperContext);

  const { icon, link, title } = props;

  return (
    <Button
      onClick={() => {
        setCurrentPage(link);
        navigate(`/dashboard${link}`);
      }}
      bg={currentPage === link ? "white" : "transparent"}
      color={currentPage === link ? "brand.purple" : "white"}
      leftIcon={icon}
      w="100%"
      borderRadius={0}
      h="3rem"
      fontWeight="500"
      colorScheme="purple"
    >
      <Text w="100%" textAlign="left">
        {title}
      </Text>
    </Button>
  );
};
