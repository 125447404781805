import { Box, Flex, Text, useMediaQuery, VStack } from "@chakra-ui/react";
import { useContext } from "react";
import { StepperContext } from "../pages/auth/SignUp";

export default () => {
  const [mobile] = useMediaQuery("(max-width: 480px)");
  const { step } = useContext(StepperContext);

  return mobile ? (
    <>
      <Flex w="100%" justifyContent="space-between">
        {[1, 2, 3, 4, 5, 6].map((_, index) => (
          <Step key={index} val={{ num: _ }} />
        ))}
      </Flex>
    </>
  ) : (
    <>
      <Flex w="100%" justifyContent="space-between" pos="relative" px="3rem">
        <Box
          h="2px"
          w="100%"
          pos="absolute"
          top={3}
          bg="gray.300"
          left={0}
          right={0}
        >
          <Box bg="brand.purple" h="100%" w={`${step * 16.7}%`} />
        </Box>
        {[
          { title: "Email", num: 1 },
          { title: "Type of account", num: 2 },
          { title: "Bio", num: 3 },
          { title: "Add password", num: 4 },
          { title: "Verify email", num: 5 },
          { title: "KYC", num: 6 },
        ].map((_, index) => {
          return <Step key={index} val={_} />;
        })}
      </Flex>
    </>
  );
};

const Step = (props: any) => {
  const {
    val: { num, title },
  } = props;

  const { step } = useContext(StepperContext);
  const [mobile] = useMediaQuery("(max-width: 480px)");

  return mobile ? (
    <Box bg={step >= num ? "brand.purple" : "gray.300"} h="2px" w="15%" />
  ) : (
    <VStack>
      <Flex
        h="1.5rem"
        w="1.5rem"
        justifyContent="center"
        alignItems="center"
        bg={step >= num ? "brand.purple" : "gray.300"}
        borderRadius="100%"
        color={step >= num ? "white" : "black"}
        zIndex={20}
      >
        {num}
      </Flex>
      <Text>{title}</Text>
    </VStack>
  );
};
