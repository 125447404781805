import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Spacer,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useContext, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { GlobalContext } from "../App";

const Dropdown = () => {
  const { authenticated } = useContext(GlobalContext);

  return authenticated ? (
    <AnimatePresence>
      <motion.div>
        <Box h="100vh" w="100%" bg="blackAlpha.50" pos="absolute" inset={0} />
        <Box
          pos="absolute"
          zIndex={50}
          top="100%"
          w="100%"
          h="fit-content"
          left={0}
          right={0}
          bg="white"
        >
          <VStack spacing={5} p={5} w="100%" alignItems="left">
            <Link href="/">Home</Link>
            <Link href="/">All transactions</Link>
            <Link href="/">Settings</Link>
            <Link href="/login" color="red">
              Logout
            </Link>
          </VStack>
        </Box>
      </motion.div>
    </AnimatePresence>
  ) : (
    <AnimatePresence>
      <motion.div>
        <Box h="100vh" w="100%" bg="blackAlpha.50" pos="absolute" inset={0} />
        <Box
          pos="absolute"
          zIndex={50}
          top="100%"
          w="100%"
          h="fit-content"
          left={0}
          right={0}
          bg="white"
        >
          <VStack spacing={5} p={5} w="100%" alignItems="left">
            <Link href="/">Home</Link>
            <Link href="/">Individual</Link>
            <Link href="/">Business</Link>
            <Link href="/">Need help</Link>
            <Link href="/login">Login</Link>
          </VStack>
        </Box>
      </motion.div>
    </AnimatePresence>
  );
};

export default () => {
  const [mobile] = useMediaQuery("(max-width: 480px)");

  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);

  return mobile ? (
    <Flex
      px={5}
      py={3}
      justifyContent="space-between"
      borderBottomWidth="1px"
      borderColor="purple.100"
      w="100%"
      alignItems="center"
      pos="relative"
    >
      {isOpen ? <Dropdown /> : null}
      <Image src="/salvapay-icon.svg" />
      <Spacer />
      <IconButton
        onClick={handleToggle}
        icon={<FiMenu size={30} />}
        aria-label="Menu"
        bg="none"
      />
    </Flex>
  ) : (
    <Flex
      px={14}
      py={5}
      justifyContent="space-between"
      borderBottomWidth="1px"
      borderColor="purple.100"
      w="100%"
    >
      <Image src="/salvapay-icon.svg" />
      <HStack w="md" spacing={8}>
        <NavLink props={{ title: "Home", link: "/", active: true }} />
        <NavLink props={{ title: "Individual", link: "/", active: false }} />
        <NavLink props={{ title: "Business", link: "/", active: false }} />
        <NavLink props={{ title: "Need help?", link: "/", active: false }} />
      </HStack>
      <HStack>
        <Button colorScheme="none" color="purple.800">
          Login
        </Button>
        <Button colorScheme="purple" bg="purple.900">
          Download App
        </Button>
      </HStack>
    </Flex>
  );
};

interface Props {
  props: {
    title: string;
    link: string;
    active: boolean;
  };
}

const NavLink = ({ props }: Props) => {
  const { active, link, title } = props;

  return (
    <Link
      _hover={{ textDecor: "none" }}
      href={link}
      color={active ? "purple.900" : "blackAlpha.700"}
      fontWeight="600"
    >
      {title}
    </Link>
  );
};
