import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { FiEye, FiEyeOff, FiLoader } from "react-icons/fi";
import Header from "../../components/Header";
import { AnimatePresence, motion } from "framer-motion";

export default () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [messageColor, setMessageColor] = useState("green");
  const [loading, setLoading] = useState(false);

  const handleEmail = (e: ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const handlePassword = (e: ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const handlePasswordVisible = () => setPasswordVisible(!passwordVisible);

  const [mobile] = useMediaQuery("(max-width: 480px)");

  return (
    <>
      {mobile ? <Header /> : null}
      <Flex
        flexFlow={{ base: "wrap column-reverse", md: "nowrap" }}
        pt={{ base: 10, md: 0 }}
      >
        <Center w={{ base: "100%", md: "50%" }}>
          <VStack
            w={{ base: "md", md: "xs" }}
            px={{ base: 6, md: 0 }}
            spacing={5}
          >
            <Image
              src="/salvapay-icon.svg"
              display={{ base: "none", md: "block" }}
            />
            <Heading
              fontSize="1.6rem"
              fontWeight="600"
              textAlign={{ base: "left", md: "center" }}
            >
              Welcome Jon Doe! It's great to have you back.
            </Heading>
            <Text w="100%" textAlign={{ base: "left", md: "center" }}>
              Please login with your detail below
            </Text>
            <FormControl pos="relative" w="100%" pt={3.5}>
              <FormLabel
                pos="absolute"
                top={0}
                left={3}
                px={2}
                bg="white"
                zIndex={20}
                color={messageColor || "black"}
              >
                Email address
              </FormLabel>
              <Input
                w="100%"
                onChange={handleEmail}
                borderColor={messageColor || "blackAlpha.400"}
              />
              {messageColor === "red" ? (
                <Text pl={5} py={1} color={messageColor}>
                  Your email address is incorrect
                </Text>
              ) : null}
            </FormControl>
            <FormControl pos="relative" w="100%" pt={3.5}>
              <FormLabel
                pos="absolute"
                top={0}
                left={3}
                px={2}
                bg="white"
                zIndex={20}
                color={messageColor === "green" ? messageColor : "black"}
              >
                Password
              </FormLabel>
              <InputGroup>
                <Input
                  w="100%"
                  onChange={handlePassword}
                  borderColor={
                    messageColor === "green" ? messageColor : "blackAlpha.400"
                  }
                  type={passwordVisible ? "text" : "password"}
                />
                <InputRightElement>
                  {passwordVisible ? (
                    <IconButton
                      icon={<FiEyeOff />}
                      aria-label="Password visible"
                      bg="transparent"
                      onClick={handlePasswordVisible}
                    />
                  ) : (
                    <IconButton
                      icon={<FiEye />}
                      aria-label="Password visible"
                      bg="transparent"
                      onClick={handlePasswordVisible}
                    />
                  )}
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Link
              href="/forgot-password"
              alignSelf="flex-start"
              fontWeight="600"
            >
              Forgot password?
            </Link>
            <Button
              w="80%"
              color="white"
              bg="brand.purple"
              _hover={{ colorScheme: "purple", opacity: 0.8 }}
              _focus={{ colorScheme: "purple", opacity: 0.8 }}
              _disabled={{ bg: "brand.gray", color: "white" }}
              isDisabled={email && password ? false : true}
              onClick={() => setLoading(!loading)}
            >
              Login
            </Button>
            <Link href="/signup" textDecor="underline">
              I'm new here. Create my account
            </Link>
            {loading ? (
              <AnimatePresence>
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                >
                  <VStack py={10} color="brand.purple">
                    <FiLoader size={30} />
                    <Text>Loading! Please wait ...</Text>
                  </VStack>
                </motion.div>
              </AnimatePresence>
            ) : null}
          </VStack>
        </Center>
        <Box
          bgImage="/auth-right-image.png"
          bgPos="center"
          h={{ base: "20rem", md: "100vh" }}
          w={{ base: "100%", md: "50%" }}
        />
      </Flex>
    </>
  );
};
