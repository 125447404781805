import React from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Menu,
  MenuButton,
  Select,
  Spacer,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import Header from "../components/Header";
import ReactCountryFlag from "react-country-flag";
import { FiChevronDown } from "react-icons/fi";
import { BsFacebook, BsLinkedin, BsTwitter } from "react-icons/bs";

export default () => {
  return (
    <VStack spacing={16}>
      <Header />
      <SendMondeyAcrossBorders />
      <WithSalvapayYouCan />
      <HowToCreateASalvapayAccount />
      <SalvapayVideo />
      <YouHaveNothingToWorryAbout />
      <SendMoneyToCountries />
      <EnoughTalkDownloadSalvapayNow />
      <SignUpOnSalvapay />
      <Footer />
    </VStack>
  );
};

const SendMondeyAcrossBorders = () => {
  return (
    <VStack maxW="2xl" w="100%" py={5} color="brand.purple" spacing={5} px={4}>
      <Heading px={5} fontSize="2.75rem" fontWeight="600" textAlign="center">
        Send Money Across Borders and Boundaries With Salvapay
      </Heading>
      <Text textAlign="center" fontWeight="500" color="black">
        Send money fast, securely, and easily across countries. Live tracking
        and notifications with flexible delivery and payment methods
      </Text>
      <VStack w="100%">
        <ExchangeRate props={{ title: "You send" }} />
        <ExchangeRate props={{ title: "Recipient receives" }} />
        <VStack
          py={5}
          w={{ base: "100%", md: "20rem" }}
          color="blackAlpha.700"
          spacing={5}
        >
          <Flex fontWeight="600" w="100%">
            <Text>Service charge</Text>
            <Spacer />
            <Text>3.5 USD</Text>
          </Flex>
          <Flex fontWeight="600" w="100%">
            <Text>Conversion rate</Text>
          </Flex>
          <Flex fontWeight="600" w="100%">
            <Text>Total amount</Text>
            <Spacer />
            <Text color="black">1,003.5 USD</Text>
          </Flex>
        </VStack>
        <Text
          pb={5}
          w={{ base: "100%", md: "20rem" }}
          color="black"
          fontSize={{ base: "1.1rem", md: "1.2rem" }}
        >
          Payment should reflect in recipient's account after 48 hours
        </Text>
        <Button colorScheme="purple" bg="brand.purple" w="85%" borderRadius={3}>
          Get started for free
        </Button>
      </VStack>
    </VStack>
  );
};

interface ExhangeProps {
  props: {
    title: string;
  };
}

const ExchangeRate = ({ props }: ExhangeProps) => {
  const { title } = props;

  return (
    <FormControl
      pt={5}
      w={{ base: "100%", md: "20rem" }}
      overflowX="hidden"
      borderRadius={3}
      pos="relative"
    >
      <FormLabel
        pos="absolute"
        top="7px"
        left="7px"
        zIndex={20}
        bg="white"
        px={2}
      >
        {title}
      </FormLabel>
      <InputGroup>
        <Input pos="relative" defaultValue="1000" size="lg" />
        <InputRightElement
          h="100%"
          w="fit-content"
          bg="brand.purple"
          color="white"
        >
          <Menu>
            <MenuButton
              as={Button}
              colorScheme="purple"
              bg="brand.purple"
              color="white"
              rightIcon={<FiChevronDown />}
              leftIcon={<ReactCountryFlag countryCode="US" svg />}
              borderRadius={5}
              h="100%"
            >
              USD
            </MenuButton>
            {/* <MenuList>
                {Array.from("1234567890").map((_, index) => {
                  return (
                    <MenuItem key={index}>
                    <MenuButton
                        w="100%"
                        as={Button}
                        colorScheme="purple"
                        bg="brand.purple"
                        rightIcon={<FiChevronDown />}
                        leftIcon={<ReactCountryFlag countryCode="US" svg />}
                        borderRadius={5}
                        >
                        USD
                        </MenuButton>
                    </MenuItem>
                    );
                  })}
              </MenuList> */}
          </Menu>
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};

const WithSalvapayYouCan = () => {
  return (
    <Box>
      <Heading
        textAlign={{ base: "left", md: "center" }}
        color="brand.purple"
        fontWeight="500"
        fontSize="2rem"
        py={5}
        px={4}
        w="100%"
      >
        With Salvapay You Can:
      </Heading>
      <Center w="100%">
        <Flex
          w="100%"
          maxW="70rem"
          gap={14}
          alignItems="center"
          flexWrap={{ base: "wrap", md: "nowrap" }}
        >
          <VStack
            alignItems="left"
            w={{ base: "100%", md: "50%" }}
            spacing={8}
            px={4}
          >
            <ReusableHeaderAndContent
              props={{
                title: "Move Money Easily",
                content:
                  "You can fund your SALVAPAY account directly from local bank account via transfer",
              }}
            />
            <ReusableHeaderAndContent
              props={{
                title: "Send Money Anywhere",
                content:
                  "Send and receive money from different countries always with a low transparent fee. We offer different ways to pay for your money transfers. But the choice will depend on where you are sending the money from.",
              }}
            />
            <ReusableHeaderAndContent
              props={{
                title: "Pay Vendors or Loved Ones",
                content:
                  "Transfer online, through our app or the web to your loved ones any time and from any place in the world",
              }}
            />
            <ReusableHeaderAndContent
              props={{
                title: "Send Without Hassle",
                content:
                  "Fast, flexible, accessible and reliable one account with multiple currencies, also with a proper time management system.",
              }}
            />
          </VStack>
          <Flex
            justifyContent="center"
            alignItems="center"
            w={{ base: "100%", md: "50%" }}
            bg="blue.100"
            borderRadius={{ md: 10 }}
            h={{ base: "25rem", md: "35rem" }}
          >
            <Image h="100%" src="/dashboard-mobile-mockup.png" />
          </Flex>
        </Flex>
      </Center>
    </Box>
  );
};

interface ReusableProps {
  props: {
    title: string;
    content: string;
  };
}

const ReusableHeaderAndContent = ({ props }: ReusableProps) => {
  const { content, title } = props;

  return (
    <VStack w="100%" alignItems="left">
      <Heading color="brand.purple" fontSize="1.8rem" fontWeight="600">
        {title}
      </Heading>
      <Text>{content}</Text>
    </VStack>
  );
};

const HowToCreateASalvapayAccount = () => {
  return (
    <VStack
      py={8}
      bgImage="/homepage-blob.svg"
      bgPos="center"
      bgSize="cover"
      bgRepeat="no-repeat"
      w="100%"
      spacing={5}
    >
      <Heading maxW="sm" fontSize="2rem" textAlign="center">
        How To Create A Salvapay Account
      </Heading>
      <Text maxW="sm" textAlign="center">
        Creating an account has never been this easy. Follow these steps.
      </Text>
      <Flex
        w="100%"
        maxW="70rem"
        justifyContent="space-between"
        wrap={{ base: "wrap", md: "nowrap" }}
      >
        <StepCard
          props={{
            title: "Register in Seconds",
            text: "This is simple. Just sign up using your email address on our app or website. And keep things secure by choosing a strong password.",
            figure: 1,
          }}
        />
        <StepCard
          props={{
            title: "Complete Your KYC",
            text: "For better security, we'll have to verify your identity. Rest assured, this should only take a moment.",
            figure: 2,
          }}
        />
        <StepCard
          props={{
            title: "Send Money As You Wish",
            text: "Select the destination country and method, and enter the amount you want to send. Our fees and exchange rates are shown upfront.",
            figure: 3,
          }}
        />
      </Flex>
      <Button w="13rem" colorScheme="purple" bg="brand.purple">
        Get started for free
      </Button>
    </VStack>
  );
};

const StepCard = ({ props }: StepCardProps) => {
  const { title, text, figure } = props;

  return (
    <VStack
      w={{ base: "100%", md: "xs" }}
      alignItems="flex-start"
      spacing={5}
      px={5}
    >
      <Flex
        w="2rem"
        h="2rem"
        justifyContent="center"
        alignItems="center"
        borderRadius="100%"
        bg="purple.300"
        fontWeight="700"
      >
        {figure}
      </Flex>
      <Heading pt={3} fontSize="1.5rem">
        {title}
      </Heading>
      <Text>{text}</Text>
    </VStack>
  );
};

const SalvapayVideo = () => {
  return (
    <Box w="100%" maxW="70rem" px={{ base: 0, md: 10 }}>
      <Image src="/video-placeholder.png" />
    </Box>
  );
};

const YouHaveNothingToWorryAbout = () => {
  return (
    <VStack py={8} w="100%" maxW="80rem" spacing={10} px={5}>
      <Heading
        w="sm"
        fontSize="2rem"
        textAlign={{ base: "left", md: "center" }}
      >
        You Have Nothing To Worry About Because
      </Heading>
      <Flex gap={5} wrap={{ base: "wrap", md: "nowrap" }}>
        <ReasonNotToWorryCard
          props={{
            image: "/wall.png",
            title: "You are protect against fraud",
            text: "We're delighted to keeping your personal data safe through proper collection, processed and securely stored to ensure your account is sophisticatedly protected against fraud.",
          }}
        />
        <ReasonNotToWorryCard
          props={{
            image: "/security.png",
            title: "You funds are fully secured",
            text: "With our global accreditation, be sure we meet the highest possible security and compliance standards.",
          }}
        />
        <ReasonNotToWorryCard
          props={{
            image: "/transaction.png",
            title: "Your transactions are done swiftly",
            text: "With no story, move your money across the world fast and securely.",
          }}
        />
        <ReasonNotToWorryCard
          props={{
            image: "/info.png",
            title: "We protect your information with us",
            text: "As a company that is dedicated to supporting customers, we take your data and its secuirty very seriously. In fact, it's super critical to us that your data remain safe, always.",
          }}
        />
      </Flex>
    </VStack>
  );
};

const ReasonNotToWorryCard = ({ props }: ReasonNotToWorryCardProps) => {
  const { image, title, text } = props;
  return (
    <VStack w={{ base: "100%", md: "25%" }} alignItems="flex-start" spacing={5}>
      <Image w="3rem" src={image} />
      <Heading pt={3} fontSize="1.5rem">
        {title}
      </Heading>
      <Text>{text}</Text>
    </VStack>
  );
};

const SignUpOnSalvapay = () => {
  return (
    <Box bg="#f0f5ff" w="100%">
      <Flex
        mx="auto"
        maxW="70rem"
        justifyContent="space-between"
        py={8}
        px={{ base: 5, md: 0 }}
        wrap="wrap"
        rowGap={8}
      >
        <Heading maxW="lg" fontSize="2rem">
          Sign Up On SalvaPay And Start Making Payment Sealessly
        </Heading>
        <Button w="xs" colorScheme="purple" bg="brand.purple">
          Get started for free
        </Button>
      </Flex>
    </Box>
  );
};

const SendMoneyToCountries = () => {
  return (
    <VStack w="100%" maxW="70rem" spacing={10} px={{ base: 5, md: 0 }}>
      <Heading fontSize="2rem" textAlign={{ base: "left", md: "center" }}>
        Send Money To 40+ Countries
      </Heading>
      <Flex w="100%" wrap="wrap" rowGap={8}>
        <CountryCard props={{ country: "United States", code: "US" }} />
        <CountryCard props={{ country: "United Kingdom", code: "GB" }} />
        <CountryCard props={{ country: "Canada", code: "CA" }} />
        <CountryCard props={{ country: "France", code: "FR" }} />
        <CountryCard props={{ country: "Spain", code: "ES" }} />
        <CountryCard props={{ country: "Germany", code: "DE" }} />
        <CountryCard props={{ country: "Switzerland", code: "CH" }} />
        <CountryCard props={{ country: "Russia", code: "RU" }} />
        <CountryCard props={{ country: "Italy", code: "IT" }} />
        <CountryCard props={{ country: "Ukraine", code: "UA" }} />
        <CountryCard props={{ country: "Poland", code: "PL" }} />
        <CountryCard props={{ country: "Romania", code: "RO" }} />
        <CountryCard props={{ country: "Netherlands", code: "NL" }} />
        <CountryCard props={{ country: "Belgium", code: "BE" }} />
        <CountryCard props={{ country: "Norway", code: "NO" }} />
        <CountryCard props={{ country: "Czech Republic", code: "CZ" }} />
        <CountryCard props={{ country: "Portugal", code: "PT" }} />
        <CountryCard props={{ country: "Greece", code: "GR" }} />
        <CountryCard props={{ country: "Sweden", code: "SE" }} />
        <CountryCard props={{ country: "Austria", code: "AT" }} />
      </Flex>
    </VStack>
  );
};

const CountryCard = ({ props }: CountryCardProps) => {
  const { code, country } = props;

  return (
    <HStack w={{ base: "100%", md: "25%" }}>
      <ReactCountryFlag countryCode={code} svg />
      <Text>{country}</Text>
    </HStack>
  );
};

const EnoughTalkDownloadSalvapayNow = () => {
  return (
    <Flex
      w="100%"
      maxW="60rem"
      px={{ base: 5, md: 10 }}
      bg="brand.purple"
      color="white"
      justifyContent="space-between"
      alignItems="center"
      borderRadius={16}
      wrap={{ base: "wrap", md: "nowrap" }}
      pt={{ base: 5, md: 0 }}
    >
      <VStack alignItems={{ base: "center", md: "flex-start" }} spacing={5}>
        <Heading
          fontSize="2rem"
          maxW="sm"
          textAlign={{ base: "center", md: "left" }}
        >
          Enough Talk, Download Salvapay App Now!
        </Heading>
        <Button bg="white" color="brand.purple">
          Download app
        </Button>
      </VStack>
      <Image pt={10} src="/phone-mockup.png" />
    </Flex>
  );
};

const Footer = () => {
  const [mobile] = useMediaQuery("(max-width: 480px)");

  return mobile ? (
    <VStack w="100%" spacing={8} px={5} pb={8}>
      <VStack alignItems="flex-start">
        <Image src="/salvapay-icon.svg" />
        <Text>Send money across borders and boundaries with Salvapay</Text>
      </VStack>
      <Flex wrap="wrap" w="100%">
        <Link w="50%" href="">
          Individual
        </Link>
        <Link w="50%" href="">
          Business
        </Link>
        <Link w="50%" href="">
          Login
        </Link>
        <Link w="50%" href="">
          Terms & Condition
        </Link>
        <Link w="50%" href="">
          Help
        </Link>
        <Link w="50%" href="">
          Privacy
        </Link>
      </Flex>
      <HStack w="100%" spacing={5}>
        <Button py={5} w="50%" colorScheme="none" color="white" bg="black">
          <HStack>
            <Image src="/apple-logo.png" />
            <VStack spacing={0} alignItems="left" textAlign="left">
              <Text fontSize="0.6rem">Download on the</Text>
              <Heading fontSize="1rem">App Store</Heading>
            </VStack>
          </HStack>
        </Button>
        <Button py={5} w="50%" colorScheme="none" color="white" bg="black">
          <HStack>
            <Image src="/playstore.png" />
            <VStack spacing={0} alignItems="left" textAlign="left">
              <Text fontSize="0.6rem">GET IT ON</Text>
              <Heading fontSize="1rem">Google Play</Heading>
            </VStack>
          </HStack>
        </Button>
      </HStack>
      <Divider />
      <HStack w="100%" spacing={5}>
        <BsTwitter />
        <BsLinkedin />
        <BsFacebook />
      </HStack>
      <Text w="100%">&copy; 2022 Salvapay. All rights reserved.</Text>
    </VStack>
  ) : (
    <Box bg="yellow.50" w="100%" py={10}>
      <Flex
        w="100%"
        maxW="75rem"
        mx="auto"
        px={5}
        justifyContent="space-between"
        wrap={{ base: "wrap", md: "nowrap" }}
      >
        <VStack alignItems="flex-start">
          <Image src="/salvapay-icon.svg" />
          <Text w="xs">
            Send money across borders and boundaries with Salvapay
          </Text>
        </VStack>
        <VStack>
          <Button py={5} w="10rem" colorScheme="none" color="white" bg="black">
            <HStack>
              <Image src="/apple-logo.png" />
              <VStack spacing={0} alignItems="left" textAlign="left">
                <Text fontSize="0.6rem">Download on the</Text>
                <Heading fontSize="1rem">App Store</Heading>
              </VStack>
            </HStack>
          </Button>
          <Button py={5} w="10rem" colorScheme="none" color="white" bg="black">
            <HStack>
              <Image src="/playstore.png" />
              <VStack spacing={0} alignItems="left" textAlign="left">
                <Text fontSize="0.6rem">GET IT ON</Text>
                <Heading fontSize="1rem">Google Play</Heading>
              </VStack>
            </HStack>
          </Button>
        </VStack>
      </Flex>
      <HStack
        my={5}
        w="100%"
        maxW="75rem"
        px={5}
        mx="auto"
        spacing={5}
        wrap="wrap"
      >
        <Link href="">Individual</Link>
        <Link href="">Business</Link>
        <Link href="">Login</Link>
        <Link href="">Terms & Condition</Link>
        <Link href="">Help</Link>
        <Link href="">Privacy</Link>
      </HStack>
      <Box w="100%" my={5} maxW="75rem" px={5} mx="auto">
        <Divider />
      </Box>
      <HStack mx="auto" w="100%" maxW="75rem" px={5} wrap="wrap">
        <Text>&copy; 2022 Salvapay. All rights reserved.</Text>
        <Spacer />
        <HStack spacing={5}>
          <BsTwitter />
          <BsLinkedin />
          <BsFacebook />
        </HStack>
      </HStack>
    </Box>
  );
};
